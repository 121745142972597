import { proxifyWithFallbackValue } from './ConfigurableText/ConfigurableText.utils'
import {
  TConfigurableCtaVariant,
  TConfigurableColorOldBranding,
  TConfigurableTitleSize,
  TConfigurableColor,
} from './contentfulPage.types'

export const ctaColors = {
  pitaya: 'pitaya',
  starfruit: 'starfruit',
} as const

export const ctaColorsOldBranding = {
  purple: 'acai',
  rose: 'pitaya',
  tree: 'starfruit',
} as const

export const buttonTypeToVariant: {
  [key in TConfigurableCtaVariant]: 'primary' | 'outline'
} = {
  outlined: 'outline',
  filled: 'primary',
}

// To be used in fluid text
export const titleSizePx = {
  XXS: '12px',
  XS: '14px',
  S: '18px',
  M: '20px',
  L: '26px',
  XL: '32px',
  '2XL': '40px',
  '3XL': '48px',
  '4XL': '56px',
}

export const titleSizeToClass: { [key in TConfigurableTitleSize]: string } = {
  XXS: 'text-[12px] leading-tight',
  XS: 'text-[14px] leading-snug',
  S: 'text-[18px] leading-snug',
  M: 'text-[18px] lg:text-[20px] leading-tight',
  L: 'text-[22px] lg:text-[26px] leading-snug',
  XL: 'text-[26px] lg:text-[32px] leading-tight',
  '2XL': 'text-[30px] lg:text-[40px] leading-tight',
  '3XL': 'text-[34px] lg:text-[48px] leading-tight',
  '4XL': 'text-[38px] lg:text-[56px] leading-tight',
}

export const subtitleSizeToClass = {
  XS: 'text-[8px] leading-snug',
  S: 'text-[12px] leading-snug',
  M: 'text-[16px] leading-snug',
  L: 'text-[20px] leading-snug',
  XL: 'text-[16px] sm:text-[24px] leading-snug',
}

const backgroundColorToClass: Record<TConfigurableColor, string> = {
  pitaya: 'bg-pitaya',
  acai: 'bg-acai',
  white: 'bg-white',
  boulder: 'bg-boulder',
  black: 'bg-black',
  pebble: 'bg-pebble',
  sand: 'bg-sand',
  paper: 'bg-paper',
}

export const mapBackgroundColorToClass = proxifyWithFallbackValue({
  initialObject: backgroundColorToClass,
  fallbackValue: backgroundColorToClass.acai,
  onFallback: (prop) => {
    // Covers for old branding values
    switch (prop) {
      case 'rose':
        return backgroundColorToClass.pitaya
      case 'purple':
        return backgroundColorToClass.acai
      case 'grey':
        return backgroundColorToClass.boulder
      case 'unbleached':
        return backgroundColorToClass.sand
    }
  },
})
